import useProject from 'hooks/project'
import React, { useState } from 'react'
import BulkPermissionsUpdate from 'views/settings/components/bulkUpdates/components/permissions'
import BulkTypesUpdate from 'views/settings/components/bulkUpdates/components/type'
import BulkArchive from 'views/settings/components/bulkUpdates/components/archive'
import Nav from 'components/nav'

const BulkUpdates: React.FC = () => {
  const { project } = useProject()

  const [tab, setTab] = useState<string>('Bulk Update Types')

  return (
    <div className="w-full overflow-x-auto" style={{ marginTop: '4px' }}>
      <Nav
        sections={['Bulk Update Types', 'Bulk Update Permissions', 'Bulk Archive Resources']}
        selectedSection={tab}
        onClick={(section) => setTab(section)}
        primaryColor={project.primaryColour}
      />
      {tab === 'Bulk Update Types' && <BulkTypesUpdate />}
      {tab === 'Bulk Update Permissions' && <BulkPermissionsUpdate />}
      {tab === 'Bulk Archive Resources' && <BulkArchive />}
    </div>
  )
}

export default BulkUpdates
