import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Pencil, Arrow, Delete, Visible, Lock, Asterisk, Filter, Group, Sort, Colour } from 'components/icons'
import { createTableColumnViewAction } from 'components/spreadsheet/contexts/data/actions'
import { IntegrationSyncTypes, ViewTypes } from 'components/spreadsheet/constants/const'
import { useProject } from 'hooks/project'
import { buildNewColumn } from 'components/spreadsheet/helpers/functions'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { ITableViewColour, ITableViewColumn, ITableViewFilter, ITableViewGroup, ITableViewSort } from 'types'
import { getDefaultFilterTypeForColType } from 'components/spreadsheet/helpers/filtering'
import constants from 'style/constants.module.scss'
import { IFilterType, sortDirections } from 'components/spreadsheet/types'
import useApplicationStore from 'hooks/application'

interface ColumnMenuProps extends MenuProps {
  setEditColumnMenu: (open: boolean) => void
  setDeleteColumnModal: (open: boolean) => void
}

const ColumnMenu: React.FC<ColumnMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  setEditColumnMenu,
  setDeleteColumnModal
}) => {
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData, setFilterMenu, setColourMenu } = useDataContext()
  const { displayErrorMessage } = useApplicationStore()

  const column = spreadsheetData.viewDetails.columns.find(
    (column: ITableViewColumn) => column.publicId === menuState.columnId
  )

  const handleInsertColumn = async (sortOrder: number) => {
    const column = buildNewColumn(
      spreadsheetData,
      sortOrder,
      project.publicId,
      project.defaultHeaderBackgroundColor,
      project.defaultHeaderTextColor
    )
    createTableColumnViewAction(
      spreadsheetData.viewDetails.publicId,
      column,
      setSpreadsheetData,
      handleOnSuccess,
      handleOnFailure
    )
  }

  const handleOnSuccess = () => {
    setMenuState({ ...menuState, open: false })
  }

  const handleOnFailure = (error: any) => {
    displayErrorMessage(error)
  }

  const handleOnClickColour = () => {
    const colourToolbarButton = document.getElementById('colour-toolbar-button')

    if (colourToolbarButton && column) {
      const isColoured =
        spreadsheetData.userConfiguration.colourSettings.findIndex(
          (setting: ITableViewColour) => setting.columnId === menuState.columnId
        ) !== -1

      if (!isColoured) {
        setColourMenu({
          open: true,
          top: `${colourToolbarButton.getBoundingClientRect().bottom + 10}px`,
          left: `${colourToolbarButton.getBoundingClientRect().left - 900 + 80}px`,
          right: 'auto',
          bottom: 'auto',
          defaultColour: {
            columnId: column.publicId,
            columnName: column.name,
            filterType: IFilterType.one_of,
            multipleValues: [],
            backgroundColour: '#FFFFFF',
            fontColour: '#000000',
            value: ''
          }
        })
      } else {
        setSpreadsheetData({
          type: 'UPDATE_COLOURS',
          colours: spreadsheetData.userConfiguration.colourSettings.filter(
            (setting: ITableViewColour) => setting.columnId !== menuState.columnId
          )
        })
      }

      setMenuState({ ...menuState, open: false })
    }
  }

  const handleOnClickFilter = () => {
    const filterToolbarButton = document.getElementById('filter-toolbar-button')

    if (filterToolbarButton && column) {
      const isFiltered =
        spreadsheetData.userConfiguration.filterSettings.findIndex(
          (setting: ITableViewFilter) => setting.columnId === menuState.columnId
        ) !== -1

      if (!isFiltered) {
        setFilterMenu({
          open: true,
          top: `${filterToolbarButton.getBoundingClientRect().bottom + 10}px`,
          left: `${filterToolbarButton.getBoundingClientRect().left - 900 + 80}px`,
          right: 'auto',
          bottom: 'auto',
          defaultFilter: {
            columnId: column.publicId,
            columnName: column.name,
            filterType: getDefaultFilterTypeForColType(column.kind),
            value: '',
            multipleValues: [],
            orGroup: 'main'
          }
        })
      } else {
        setSpreadsheetData({
          type: 'UPDATE_FILTERS',
          filters: spreadsheetData.userConfiguration.filterSettings.filter(
            (setting: ITableViewFilter) => setting.columnId !== menuState.columnId
          )
        })
      }

      setMenuState({ ...menuState, open: false })
    }
  }

  const columnSorted =
    column &&
    spreadsheetData.userConfiguration.sortSettings.find(
      (sortSetting: ITableViewSort) => sortSetting.columnId === column.publicId
    )

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        {spreadsheetData.isAdmin && (
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => setEditColumnMenu(true)}
            >
              <Pencil />
              <span className="ml-10px">Edit Column</span>
            </div>
          </li>
        )}

        {spreadsheetData.isAdmin && (
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => {
                if (menuState.columnNumber !== undefined) handleInsertColumn(menuState.columnNumber)
              }}
            >
              <Arrow type="left" />
              <span className="ml-10px">Insert (Before)</span>
            </div>
          </li>
        )}

        {spreadsheetData.isAdmin && (
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: `1px solid ${constants.grey}` }} />
        )}

        {spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => handleOnClickFilter()}
            >
              <Filter />
              <span className="ml-10px">
                {`${
                  spreadsheetData.userConfiguration.filterSettings.findIndex(
                    (setting: ITableViewFilter) => setting.columnId === menuState.columnId
                  ) === -1
                    ? 'Add Filter'
                    : 'Remove Filters'
                }`}
              </span>
            </div>
          </li>
        )}

        {spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => handleOnClickColour()}
            >
              <Colour />
              <span className="ml-10px">
                {`${
                  spreadsheetData.userConfiguration.colourSettings.findIndex(
                    (setting: ITableViewColour) => setting.columnId === menuState.columnId
                  ) === -1
                    ? 'Add Colour'
                    : 'Remove Colours'
                }`}
              </span>
            </div>
          </li>
        )}

        {spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => {
                if (column) {
                  const isGrouped =
                    spreadsheetData.userConfiguration.groupSettings.findIndex(
                      (setting: ITableViewGroup) => setting.columnId === menuState.columnId
                    ) !== -1

                  if (isGrouped) {
                    setSpreadsheetData({
                      type: 'DELETE_GROUP',
                      columnId: column.publicId
                    })
                  } else {
                    setSpreadsheetData({
                      type: 'ADD_GROUP',
                      columnId: column.publicId,
                      columnName: column.name
                    })
                  }

                  handleOnSuccess()
                }
              }}
            >
              <Group />
              <span className="ml-10px">
                {`${
                  spreadsheetData.userConfiguration.groupSettings.find(
                    (setting: ITableViewGroup) => setting.columnId === menuState.columnId
                  )
                    ? 'Ungroup'
                    : 'Group'
                }`}{' '}
                Column
              </span>
            </div>
          </li>
        )}

        {(!columnSorted || (columnSorted && columnSorted.direction === sortDirections.desc)) &&
          spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (column) {
                    if (columnSorted) {
                      setSpreadsheetData({
                        type: 'DELETE_SORT',
                        columnId: column.publicId
                      })
                    }
                    setSpreadsheetData({
                      type: 'ADD_SORT',
                      columnId: column.publicId,
                      columnName: column.name
                    })
                  }
                  handleOnSuccess()
                }}
              >
                <Sort />
                <span className="ml-10px">Sort Ascending</span>
              </div>
            </li>
          )}

        {(!columnSorted || (columnSorted && columnSorted.direction === sortDirections.asc)) &&
          spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (column) {
                    if (columnSorted) {
                      setSpreadsheetData({
                        type: 'DELETE_SORT',
                        columnId: column.publicId
                      })
                    }
                    setSpreadsheetData({
                      type: 'ADD_SORT',
                      columnId: column.publicId,
                      columnName: column.name
                    })
                    setSpreadsheetData({
                      type: 'CHANGE_SORT_DIRECTION',
                      columnId: column.publicId,
                      newDirection: sortDirections.desc
                    })
                  }
                  handleOnSuccess()
                }}
              >
                <Sort />
                <span className="ml-10px">Sort Descending</span>
              </div>
            </li>
          )}

        {columnSorted && spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => {
                if (column) {
                  setSpreadsheetData({
                    type: 'DELETE_SORT',
                    columnId: column.publicId
                  })
                }
                handleOnSuccess()
              }}
            >
              <Delete />
              <span className="ml-10px">Remove Sort</span>
            </div>
          </li>
        )}

        {spreadsheetData.isAdmin && spreadsheetData.viewDetails.type !== ViewTypes.FORM && (
          <hr style={{ marginTop: '5px', marginBottom: '5px', border: `1px solid ${constants.grey}` }} />
        )}

        {spreadsheetData.isAdmin && (
          <div>
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (menuState.columnId !== undefined) {
                    setSpreadsheetData({ type: 'HIDE_COLUMN', columnId: menuState.columnId })
                  }

                  handleOnSuccess()
                }}
              >
                <Visible />
                <span className="ml-10px">Hide Column</span>
              </div>
            </li>
            {(!column ||
              (column.viewpointSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.viewpointRfisSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.autodeskBim360Synced === IntegrationSyncTypes.NOT_SYNCED &&
                column.autodeskBim360ChecklistsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.procoreSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.mortaSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.reviztoIssuesSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.asiteDocumentsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.aconexWorkflowsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.aconexSynced === IntegrationSyncTypes.NOT_SYNCED)) && (
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{
                    padding: '0 10px',
                    height: '32px',
                    lineHeight: '32px'
                  }}
                  onClick={() => {
                    if (column) {
                      if (column.locked) {
                        setSpreadsheetData({ type: 'UNLOCK_COLUMN', columnId: column.publicId })
                      } else {
                        setSpreadsheetData({ type: 'LOCK_COLUMN', columnId: column.publicId })
                      }
                    }

                    handleOnSuccess()
                  }}
                >
                  <Lock />
                  <span className="ml-10px">{`${column && column.locked ? 'Unlock' : 'Lock'} Column`}</span>
                </div>
              </li>
            )}

            {spreadsheetData.viewDetails.type === ViewTypes.FORM && column && (
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{
                    padding: '0 10px',
                    height: '32px',
                    lineHeight: '32px'
                  }}
                  onClick={() => {
                    if (!column.required) {
                      setSpreadsheetData({ type: 'REQUIRE_COLUMN', columnId: column.publicId })
                      handleOnSuccess()
                    } else if (column.required) {
                      setSpreadsheetData({ type: 'UNREQUIRE_COLUMN', columnId: column.publicId })
                      handleOnSuccess()
                    }
                  }}
                >
                  <Asterisk />
                  <span className="ml-10px">Set {column.required ? 'Unrequired' : 'Required'}</span>
                </div>
              </li>
            )}

            {(!column ||
              (column.viewpointSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.viewpointRfisSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.autodeskBim360Synced === IntegrationSyncTypes.NOT_SYNCED &&
                column.autodeskBim360ChecklistsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.procoreSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.mortaSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.reviztoIssuesSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.asiteDocumentsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.aconexWorkflowsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.aconexSynced === IntegrationSyncTypes.NOT_SYNCED)) && (
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-really-light-red"
                  style={{
                    padding: '0 10px',
                    height: '32px',
                    lineHeight: '32px'
                  }}
                  onClick={() => setDeleteColumnModal(true)}
                >
                  <Delete />
                  <span className="ml-10px" style={{ color: constants.red }}>
                    Delete Column
                  </span>
                </div>
              </li>
            )}
          </div>
        )}
      </div>
    </Menu>
  )
}

export default React.memo(ColumnMenu)
